
import React, { useEffect, useState, useRef } from "react";
import {
    Button, Tooltip, message, Dropdown, Spin, Menu, Empty, Checkbox, Modal
} from 'antd';
import RemakeRecognition from "./RemakeRecognition";
import { LoadingOutlined, PicLeftOutlined, DownloadOutlined, StarOutlined, PushpinOutlined, EllipsisOutlined, DownOutlined, CloudServerOutlined, ContainerOutlined } from "@ant-design/icons";
import XIconFont from "@/components/IconFont";
import RViewerJS from 'viewerjs-react';
import commonService from "@/service/common";
const GalleryComponent = (props) => {
    const {
        galleryData,
        loding,
        lodeMore,
        scrollData,
        onlinePreview,
        pageIndex
    } = props;

    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const [checkIndex, setCheckIndex] = useState(null);
    const [downloadLoding, setDownloadLoding] = useState(null);
    const [remakeModalOpen, setRemakeModalOpen] = useState(false);
    const [selectData, setSelectData] = useState(null);
    const actionsMenu = (data) => {
        return <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <a>
                            图片理解
                        </a>
                    ),
                    icon: <ContainerOutlined />,
                    disabled: true,
                },
                {
                    key: '5',
                    label: (
                        <a onClick={() => { remakeRecognition(data) }}>
                            翻拍识别
                        </a>
                    ),
                    icon: <CloudServerOutlined />,
                },
                {
                    key: '2',
                    label: (
                        <a onClick={() => { downLoadFile(data) }}>
                            下载
                        </a>
                    ),
                    icon: <DownloadOutlined />,
                },
                {
                    key: '3',
                    label: (
                        <a>
                            收藏
                        </a>
                    ),
                    icon: <StarOutlined />,
                    disabled: true,
                },

                {
                    key: '4',
                    label: (
                        <a>
                            打标
                        </a>
                    ),
                    icon: <PushpinOutlined />,
                    disabled: true,
                }, {
                    key: '4',
                    label: (
                        <a>
                            会议详情
                        </a>
                    ),
                    icon: <PicLeftOutlined />,
                    disabled: true,
                },
            ]}
        />
    }
    const downLoadFile = (data) => {
        const link = document.createElement("a");
        link.style.display = "none";
        link.target = "_blank";
        link.href = data.url;
        link.click();
    }
    const remakeRecognition = (data) => {
        setSelectData(data)

        setRemakeModalOpen(true)
    }
    const copyUrl = (data) => {
        const input = document.createElement("input");
        input.value = data.url;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        message.success("复制成功");
    }
    const onCheckChange = (checkedValues) => {
        setCheckedList(checkedValues);
        setIndeterminate(!!checkedValues.length && checkedValues.length < galleryData.length);
        setCheckAll(checkedValues.length === galleryData.length);
        console.log('checked = ', checkedValues);
    };
    const onCheckAllChange = (e) => {
        console.log("e.target.checked", e.target.checked);
        setCheckedList(e.target.checked ? galleryData : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    const downloadFiles = () => {
        let params = []
        checkedList.forEach(element => {
            params.push({
                url: element.url,
                name: element.downloadFileName
            })
        });

        const date = new Date();
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);
        let fileName = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        setDownloadLoding(true)
        commonService.DownloadFiles(params).then(res => {

            let blob = new Blob([res], { type: 'application/zip' }); // application/zip就是设置下载类型，需要设置什么类型可在标题二处查看，
            const url = window.URL.createObjectURL(blob); // 设置路径
            const link = window.document.createElement('a'); // 创建a标签
            link.href = url;
            link.setAttribute('download', fileName); // 给下载后的文件命名
            link.style.display = 'none';
            link.click();
            URL.revokeObjectURL(url); // 释放内存
            setDownloadLoding(false)
        })
    }


    const onMouseLeave = (index) => {
        if (index == checkIndex) {
            setCheckIndex(null)
        } else {
            setCheckIndex(index)
        }
    }
    return (<>
        {
            checkedList.length > 0 && <div className="checkNum">
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    全选
                </Checkbox>
                <span>已选择 {checkedList.length} 项</span>
                <div className="btnBox">
                    <Button
                        onClick={() => { downloadFiles() }}
                        type="link"
                        style={{
                            margin: '0 8px',
                        }}
                    >
                        {downloadLoding ? <LoadingOutlined /> : <DownloadOutlined />} 打包下载
                    </Button>
                </div>
            </div>
        }

        <RViewerJS>
            <ul className="scrollUl"
                onScroll={(scrollTop) => { pageIndex < 2 && scrollData(scrollTop) }}
            >
                <p className="titleP">文件</p>
                {galleryData && galleryData.length > 0 ?
                    <Checkbox.Group
                        value={checkedList}
                        onChange={onCheckChange}
                    >
                        {galleryData.map((imgData, indexM) => (

                            <li className="imgItem"
                                onMouseEnter={() => { setCheckIndex(indexM) }} onMouseLeave={() => { onMouseLeave(indexM) }}
                            >
                                <Checkbox style={{ display: (indexM == checkIndex || checkedList.length > 0) ? 'inline-flex' : 'none' }} value={imgData}></Checkbox>
                                <Dropdown
                                    className="dropdownBtn"
                                    overlay={() => actionsMenu(imgData)}
                                    trigger={["click"]}
                                    placement="bottom"
                                >
                                    <EllipsisOutlined />
                                </Dropdown>
                                {
                                    ['xlsx', 'pdf', 'docx'].includes(imgData.fileExtendType) ?
                                        <a target="_blank" className="imgP" onClick={() => { onlinePreview(imgData) }}>
                                            <XIconFont type={imgData.fileExtendType == 'xlsx' ? "icon-ECEL" : (imgData.fileExtendType == 'docx' ? "icon-WORD" : "icon-PDF")} className="item-icon" />
                                        </a>
                                        : <img
                                            onError={(e) => {
                                                e.target.src = 'https://spkg.oss-cn-hangzhou.aliyuncs.com/resources/images/errorImg.png';
                                            }}
                                            className="imgP" src={imgData.url}></img>
                                }

                                <Tooltip title={imgData.downloadFileName}> <p>{imgData.downloadFileName}</p></Tooltip>
                                <Tooltip title={imgData.createdOn}> <p>{imgData.createdOn}</p></Tooltip>
                                <Tooltip title={imgData.fileSize}> <p>{imgData.fileSize}</p></Tooltip>
                            </li>
                            // </Checkbox>
                        ))
                        }
                    </Checkbox.Group> : <Empty />
                }
                {
                    loding && <div className="loadMore"><Spin tip="加载中..."></Spin></div>
                }
                {
                    pageIndex >= 2 && !loding &&
                    <div className="loadMore"><Button
                        onClick={() => { lodeMore() }}
                        style={{
                            margin: '0 8px',
                        }}
                    >
                        <DownOutlined />加载更多
                    </Button></div>
                }
            </ul>


        </RViewerJS>
        <Modal
            title="翻拍识别-实验"
            width="100%"
            open={remakeModalOpen}
            onCancel={() => { setRemakeModalOpen(false) }}
            footer={[
            ]}>
            <RemakeRecognition modeType={'retake-recognition'} selectData={selectData}></RemakeRecognition>
        </Modal>
    </>)
}
export default GalleryComponent;