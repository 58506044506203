import React, { useEffect, useState, useRef } from "react";
import SmartQueryReact from "smart-iquery-react";
import { SmartStorage } from "@/utils";
import * as tinyCookie from "tiny-cookie";
import { Modal, message, notification, Button, Empty } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import iSignalrM from "../../controllers/smart-signalr";
import "./index.less";
import LectureManage from "./LectureManage/index.jsx";
import Finance from "./Finance/index.jsx";
import { useHistory } from "react-router-dom";
// 引入弹窗组件
import IqueryDetailDrawer from "@/components/DetailDrawer/indexIquery";
import Action from "@/pages/HomePage/Action";
import QueryModal from "@/pages/HomePage/PageModal/QueryModal";
import BatchAudit from "@/pages/HomePage/BatchAudit";
import SupplementModal from "@/pages/HomePage/SupplementModal";
import DynFormAction from "./DynFormAction/index";
import GenericBatch from "./GenericBatch/index";
import * as monaco from "monaco-editor";
import commonService from "@/service/common";
// eslint-disable-next-line no-unused-vars
import { getCookie, setCookie } from "tiny-cookie";
import Insight from "@/pages/HomePage/Finance/Insight";
import FinConfirmReceipt from "@/pages/HomePage/Finance/FinConfirmReceipt";
import DiffReconciliation from "@/pages/HomePage/Finance/DiffReconciliation";
import TripOrderGcar from "@/pages/HomePage/TripOrderGcar";
import FundLogDetails from "@/pages/HomePage/FundManage/FundLogDetails";
import InvoicePlatformCollect from "@/pages/HomePage/Finance/InvoicePlatformCollect";
import RestaurantHandler from "@/pages/HomePage/RestaurantHandler/RestaurantHandler";
import ThirdPartApp from "@/pages/HomePage/ThirdPartApp/ThirdPartApp";
import MopLog from "@/components/DetailDrawer/compments/MopModules/MopLog";
import QrcodeModal from "./PageModal/QrcodeModal";
import CustomerDetail from "./CustomerDetail";
import ConfirmSettlementCode from "@/pages/HomePage/ConfirmSettlementCode";
import VenueImageDetail from "@/pages/HomePage/VenueImageDetail";
import EditCityLevelAvgPrice from "@/pages/HomePage/CityLevelAvgPrice";
import HotelAuditEdit from "@/pages/HomePage/HotelAuditEdit";
import CityAuditEdit from "@/pages/HomePage/HotelAuditEdit/cityAuditEdit";
import remindSrc from '@/assets/audios/alert.mp3'
import {
  FileTextOutlined,
} from '@ant-design/icons';
import Xform from "@/components/Xform/xformRender";
import Yida from "@/components/YidaRender/yidaRender";
import XformContext from "@/components/Xform/xformContext"

let monacoEditor = null;
const { confirm } = Modal;
const userId =
  SmartStorage.get("userId") ||
  SmartStorage.get("user_id") ||
  tinyCookie.get("userId") ||
  tinyCookie.get("user_id");
const IqueryPageList = (props) => {
  var refreshHub = new iSignalrM({
    hubName: "SmartHub",
    queryParams:
      "group=gcar-web-message-trips",
    listeners: [
      {
        methodName: "igsk-car-web-trips",
        method: function (data) {
          console.log(data, "data-----")
          data.Sender != SmartStorage.get("userInfo").userName && notification.warning({
            message: "新消息来啦",
            description: (
              <div className="messageChatBox" onClick={() => goMessagePage(data)}>
                <p>编号：{data.MeetingCode}</p>
                <p>{data.Sender}:{data.SendTime}</p>
                <p>内容：{data.ContentText}</p>
              </div>
            ),
            placement: "topRight",
            duration: null,
          });
        },
      },
    ],
    host: process.env.REACT_APP_SIGNALER,
    // host: 'https://signalr.smartmice.cn'
  });
  var refreshPayHub = new iSignalrM({
    hubName: "taskHub",
    queryParams: "group=" + userId,
    listeners: [
      {
        methodName: "newTask",
        method: function (data) {
          console.log(data, "data-----")
          setTimeout(function () {
            var audio = new Audio(remindSrc);
            audio.play().catch(error => {
              console.error("音频播放失败:", error);
            });
            reloadData();
          }, 3000);
        },
      },
    ],
    host: process.env.REACT_APP_SIGNALER,
  });
  const componentWillUnmount = () => {
    refreshHub.uninstall();
    refreshPayHub.uninstall();
  };
  useEffect(() => {
    let role_codes = SmartStorage.get("x_role_codes") || getCookie("x_role_codes") || SmartStorage.get("role_codes") || getCookie("role_codes");
    role_codes && role_codes.indexOf('EX.GCAR.SERVICE.CONTACT') > 0 && refreshHub.install();
    role_codes && role_codes.indexOf('EX.FIN3') > -1 && refreshPayHub.install();
    return componentWillUnmount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const queryModalRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const LectureManageRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const DynFormActionRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const GenericBatchRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const FinanceRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const FinConfirmReceiptRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const DiffReconciliationRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const FundLogDetailsRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const InvoicePlatformCollectRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const RestaurantHandlerRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const TripOrderGcarRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const ThirdPartAppRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const ConfirmSettlementCodeRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const VenueImageDetailRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const EditCityLevelAvgPriceRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const HotelAuditEditRef = useRef();
  const CityAuditEditRef = useRef();

  const modelComponents = {
    DynFormAction,
    LectureManage,
    GenericBatch,
    Finance,
    FinConfirmReceipt,
    FundLogDetails,
    DiffReconciliation,
    InvoicePlatformCollect,
    RestaurantHandler,
    TripOrderGcar,
    ThirdPartApp,
    ConfirmSettlementCode,
    VenueImageDetail,
    EditCityLevelAvgPrice,
    HotelAuditEdit,
    CityAuditEdit
  };

  // const gatewayPath="https://gateway-mice.smartmice.cn"
  const [iqueryDetailDrawerVisible, setIqueryDetailDrawerVisible] =
    useState(false);

  // [{queryId: '554', queryId4Count: '555', cluster: 'default',"tableType":'tableDetails'}]
  // const getQueryData = urlParamsObjArr[0].queryId
  //   ? urlParamsObjArr
  //   : SmartStorage.get("menuParams")?.UrlParams;
  // const getQueryData = urlParamsObjArr[0].queryId?urlParamsObjArr: SmartStorage.get("menuParams")?.UrlParams;
  // console.log(getQueryData, "getQueryData");
  // let iSignalr;
  // let gatewayPath;
  // let headerPortals;
  const [iSignalr, setISignalr] = useState(null);
  const [gatewayPath, setGatewayPath] = useState(null);
  const [getQueryData, setGetQueryData] = useState(null);
  const [headerPortals, setHeaderPortals] = useState(null);
  const [urlParamsObjArr, setUrlParamsObjArr] = useState(null);
  const [drawerData, setDrawerData] = useState({});
  const [radioQueryData, setRadioQueryData] = useState(null);
  const [handleMethodData, setHandleMethodData] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [mongodbSet, setMongodbSet] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [rowDate, setRowDate] = useState(undefined);
  const [rowid, setRowId] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listData, setListData] = useState(null);
  const [dataParame, setDataParame] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [actionBtn, setActionBtn] = useState({});

  //query详情
  const [queryModalData, setQueryModalData] = useState([]);
  const [queryModalBtnData, setQueryModalBtnData] = useState({});
  const [mopModalData, setMopModalData] = useState({});
  const [queryModalVisible, setQueryModalVisible] = useState(false);
  const [mopModalVisible, setMopModalVisible] = useState(false);
  const [selectQueryModalData, setSelectQueryModalData] = useState([]);
  const [queryFileList, setQueryFileList] = useState([]);

  const [batchAuditVisible, setBatchAuditVisible] = useState(false);
  const [queryObject, setQueryObject] = useState({});
  const [messageModalVisible, setMessageModalVisible] = useState(false);

  // 二维码弹窗状态
  const [qrcodeVisable, setQrcodeVisable] = useState(false);
  const [qrcodeData, setQrcodeData] = useState({});
  const [viewFileVisible, setViewFileVisible] = useState(false);

  const [UploadVisible, setUploadVisible] = useState(false);
  const [uploadProps, setUploadProps] = useState();
  const [UploadTitle, setUploadTitle] = useState('补发票');
  const [UploadItem, setUploaditem] = useState(null);
  const [fileList, setFileList] = useState([]);

  //xform状态数据
  const [XformData, setXformData] = useState(null);
  const xFormModalRef = useRef();
  useEffect(() => {
    if (XformData) {
      xFormModalRef.current.handelFormAction({
        actionType: "openModal",
      })
    }
  }, [XformData]);

  // yida状态数据
  const [yidaOpen, setYidaOpen] = useState(false);
  const [yidaModel, setYidaModel] = useState(null);
  const [yidaModeltitle, setYidaModeltitle] = useState("");
  useEffect(() => {
    if (yidaModel) {
      setYidaOpen(true);
    }
  }, [yidaModel]);
  const subassemblyList = SmartStorage.get("subassembly") || [];
  const [modules, setModules] = useState(["DiffReconciliation"]);
  const components = {
    Insight,
  };
  /**
    *
    * @param {object} btn 按钮信息
    * @param {object} record 行数据
    */
  const applyYida = (btn, record) => {
    setYidaModeltitle(btn.actionName);
    setYidaModel({
      modalWidth: btn?.modalWidth,
      pageId: btn.actionCode,
      reloadData,
      setYidaOpen,
      record,
      gateway: process.env.REACT_APP_GATEWAY,
      requestData: btn.Request
    });
  };

  /**
  *
  * @param {object} btn 按钮信息
  * @param {object} record 行数据
  */
  const applyXform = (btn, record) => {
    setXformData({
      modalWidth: btn?.modalWidth,
      formRef: btn.actionCode,
      record: record,
    });
  };
  let checkFile = async (btn, record) => {

    let str = JSON.stringify(btn.request)
    // 定义正则表达式来匹配 ${xxx} 这种形式的占位符
    let regex = /\$\{[^}]+\}/g;

    // 使用 match 方法进行匹配
    let matches = str.match(regex);
    matches.forEach((match) => {
      str = str.replace(match, eval("`" + match + "`"))
    })

    let res = await commonService.common("post", "/ndata/api/xquery/getQueryData", str)
    console.log("====查看文件返回====", res);
    if (res && res.success && res.content) {
      let fileList = res.content.rows;
      if (fileList.length) {
        setQueryFileList(fileList)
      } else {
        setQueryFileList([])
        console.log("====文件列表为空====");
      }
      setViewFileVisible(true);
    }

  }

  // queryData处理
  const getQueryDatahandle = () => {
    // 获取参数的兼容操作
    let url = window.location.search.substring(1);
    let urlParamsObj = {};
    if (url) {
      let urlParams = url.split("&");
      urlParams.forEach((item) => {
        let temp = item.split("=");
        // console.log(decodeURI(temp[1]))
        urlParamsObj[temp[0]] = decodeURI(temp[1]);
      });
    }
    console.log(urlParamsObj);
    urlParamsObj.cluster = "default";
    let urlParamsObjArr = [];
    let additionalObj = {};
    urlParamsObj && urlParamsObjArr.push(urlParamsObj);
    urlParamsObjArr = urlParamsObjArr.map((item) => {
      if (item.additional) {
        let arr = item.additional.replace("{", "").replace("}", "").split(":");
        additionalObj[arr[0]] = arr[1];
        item.additional = additionalObj;
        console.log(arr);
      }
      return item;
    });
    setUrlParamsObjArr(urlParamsObjArr);

    let queryData = urlParamsObjArr[0].queryId
      ? urlParamsObjArr
      : SmartStorage.get("menuParams")?.UrlParams.length > 0
        ? SmartStorage.get("menuParams")?.UrlParams
        : queryAccessNodesByIQuery();
    if (queryData) {
      // iSignalr =
      //   (queryData && queryData[0].iSignalr) || process.env.REACT_APP_SIGNALER;
      // headerPortals = queryData && queryData[0]?.headerPortals;

      // gatewayPath =(queryData && queryData[0].gatewayPath) ||
      // process.env.REACT_APP_GATEWAY
      setGatewayPath(
        (queryData && queryData[0].gatewayPath) || process.env.REACT_APP_GATEWAY
      );
      setISignalr(
        (queryData && queryData[0].iSignalr) || process.env.REACT_APP_SIGNALER
      );
      setHeaderPortals(queryData && queryData[0]?.headerPortals);

      queryData[0]?.mongodbSet && setMongodbSet(queryData[0]?.mongodbSet);
      queryData[0]?.component && setModules(queryData[0]?.component);
      console.log("====queryData====", queryData);
      setRadioQueryData(queryData ? queryData[0] : {});
      setGetQueryData(queryData);
    }
  };
  // 后端提供queryData数据
  const queryAccessNodesByIQuery = () => {
    if (SmartStorage.get("menuParams")?.Method) {
      QueryV1AccessNodesByIQuery()
    } else {
      accessNodesByIQuery()
    }
  }
  const QueryV1AccessNodesByIQuery = () => {
    let params = {
      "FlowCode": "EX.PayOnline",
      "TaskStatus": 0
    };
    commonService.QueryV1AccessNodesByIQuery(params).then((res) => {
      if (res && res.success) {
        const regQqueryData = res.content.map((item) => {
          item.queryName = `${item.queryName}(${item.countTask})`;
          return item;
        });
        setHeaderPortals(res.content && res.content[0]?.headerPortals);
        setGatewayPath(
          (regQqueryData && regQqueryData[0]?.gatewayPath) ||
          process.env.REACT_APP_GATEWAY
        );
        setISignalr(
          (regQqueryData && regQqueryData[0]?.iSignalr) ||
          process.env.REACT_APP_SIGNALER
        );
        setHeaderPortals(regQqueryData && regQqueryData[0]?.headerPortals);

        regQqueryData[0]?.mongodbSet &&
          setMongodbSet(getQueryData[0]?.mongodbSet);
        regQqueryData[0]?.component && setModules(regQqueryData[0]?.component);
        setRadioQueryData(regQqueryData ? regQqueryData[0] : {});
        setGetQueryData(regQqueryData);
      }
    });
  }
  // 后端提供queryData数据
  const accessNodesByIQuery = () => {
    let params = {
      filters: {
        category: "MOP-GROUP-V1",
      },
    };
    commonService.accessNodesByIQuery(params).then((res) => {
      if (res && res.success) {
        const regQqueryData = res.content.map((item) => {
          item.queryName = `${item.queryName}(${item.countTask})`;
          return item;
        });
        // iSignalr =
        //   (regQqueryData && regQqueryData[0].iSignalr) ||
        //   process.env.REACT_APP_SIGNALER;
        // gatewayPath =
        //   (regQqueryData && regQqueryData[0].gatewayPath) ||
        //   process.env.REACT_APP_GATEWAY;
        // headerPortals = regQqueryData && regQqueryData[0]?.headerPortals;
        setGatewayPath(
          (regQqueryData && regQqueryData[0]?.gatewayPath) ||
          process.env.REACT_APP_GATEWAY
        );
        setISignalr(
          (regQqueryData && regQqueryData[0]?.iSignalr) ||
          process.env.REACT_APP_SIGNALER
        );
        setHeaderPortals(regQqueryData && regQqueryData[0]?.headerPortals);

        regQqueryData[0]?.mongodbSet &&
          setMongodbSet(getQueryData[0]?.mongodbSet);
        regQqueryData[0]?.component && setModules(regQqueryData[0]?.component);
        setRadioQueryData(regQqueryData ? regQqueryData[0] : {});
        setGetQueryData(regQqueryData);
      }
    });
  };
  // 行数据选中回调
  const returnSelectedRows = (rowsId, rows) => {
    console.log(rowsId);
    setSelectedRowKeys(rowsId);
    setSelectedRows(rows);
  };
  const goMessagePage = (data) => {
    let Path = '/customer-service-records';
    let menu = SmartStorage.get("menus").find((item) => item.Path == Path);
    let UrlParam = menu.Params.UrlParams
    SmartStorage.set("UrlParams", UrlParam);

    let breadRoutes = SmartStorage.get("breadRoutes");

    breadRoutes[breadRoutes.length - 1].breadcrumbName = '客服记录';
    breadRoutes[breadRoutes.length - 1].path = Path;
    SmartStorage.set("breadRoutes", breadRoutes);
    SmartStorage.set("menuParams", { UrlParams: UrlParam });
    SmartStorage.set("selectedKeys", Path);

    const rnd = new Date().getTime();
    history.push({
      pathname: "/customer-service-records?keyword=" + data.MeetingCode + "&rnd=" + rnd,
    });
  };

  const showJsonViewer = (text, record) => {
    console.log(text, record);
    monacoEditor = null;
    setModalVisible(true);
    setTimeout(() => {
      monacoEditor = monaco.editor["create"](
        document.querySelector("#containerId"),
        {
          value: JSON.stringify(text),
          language: "json",
          theme: "vs-dark",
        }
      );
      setTimeout(() => {
        monacoEditor.trigger("editor", "editor.action.formatDocument");
      }, 150);
    }, 200);
  };
  // 查看发票
  const viewInvoice = (record) => {
    window.open(record.url);
  };
  // 操作数据方法调用数据结构中大api
  const operationMethod = (itemObj, rowData) => {
    confirm({
      title: itemObj.text || itemObj.actionName,
      icon: <ExclamationCircleFilled />,
      mask: true,
      maskClosable: true,
      content: (
        <div>
          <p>确定{itemObj.text || itemObj.actionName}吗？</p>
        </div>
      ),
      okText: "确定",
      cancelText: "取消",
      onOk() {
        if (JSON.stringify(itemObj.parameters) !== "{}") {
          let requestParams = itemObj.parameters;
          if (requestParams.api && requestParams.body) {
            for (let key in requestParams.body) {
              if (requestParams.body[key] === "") {
                requestParams.body[key] = rowData[key];
              }
            }
            // commonHandle(requestParams);
            requestCommon(requestParams);
          }
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const requestCommon = async (params) => {
    let res = await commonService.common("post", params.api, params.body);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      reloadData();
    }
  };
  const genericMethod = (btn, rowKeys, selectedRows, record) => {
    let selectedKeys = SmartStorage.get("selectedKeys");
    confirm({
      title: btn.text || btn.actionName,
      icon: <ExclamationCircleFilled />,
      mask: true,
      maskClosable: true,
      content: (
        <div>
          <p>确定{btn.text || btn.actionName}吗？</p>
        </div>
      ),
      okText: "确定",
      cancelText: "取消",
      onOk() {
        let parameters = btn.parameters;
        let params = {};
        if (parameters.constant) {
          params = parameters.constant;
        } else {
          for (let key in parameters.body) {
            console.log(key);
            params[key] = record[key];
            if (
              selectedKeys === "/report/invoice-boxed" &&
              selectQueryModalData
            ) {
              let obj = Object.assign(record, selectQueryModalData);
              params[key] = obj[key];
            }
          }
        }
        commonHandle(params, btn);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  // 发票确认完成
  const confirmMethod = (btn, rowKeys) => {
    confirm({
      title: btn.text || btn.actionName,
      icon: <ExclamationCircleFilled />,
      mask: true,
      maskClosable: true,
      content: (
        <div>
          <p>确定{btn.text || btn.actionName}吗？</p>
        </div>
      ),
      okText: "确定",
      cancelText: "取消",
      onOk() {
        let parameters = btn.parameters;
        for (let key in parameters.body) {
          console.log(key);
          console.log(rowKeys);
          if (key === "invoiceIdList") {
            parameters.body[key] = rowKeys;
          }
        }
        commonHandle(parameters.body, btn);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const commonHandle = async (params, btn) => {
    let parameters = btn.parameters;
    let url = parameters.api;
    let method = "post";
    let res = await commonService.common(method, url, params);
    if (res && res.success) {
      message.success(res.msg || "操作成功");
      let selectedKeys = SmartStorage.get("selectedKeys");
      if (
        selectedKeys === "/report/invoice-boxed" &&
        btn.actionCode === "confirmBox"
      ) {
        setQrcodeVisable(true);
        setQrcodeData(res.content);
        // queryModalVisibleCallback(true);
      } else if (
        selectedKeys === "/report/invoice-boxed" &&
        btn.actionCode === "removeBind"
      ) {
        reloadData();
        queryModalVisibleCallback(true);
      } else if (btn.actionCode === "btn-export") {
        window.open(res.content.url)
      } else {
        reloadData();
      }
    }
  };
  const showQueryModal = (btn, record) => {
    if (
      btn.Request.api === "/api/iwork/infoBinded/bindedConfireInner" &&
      Array.isArray(record) &&
      record.length < 1
    ) {
      message.info("请勾选快递");
      return;
    }
    setQueryModalBtnData(btn);
    let obj = {
      queryId: btn.Request?.queryId || record?.queryId,
      queryId4Count: btn.Request?.queryId4Count || record?.queryId4Count,
      cluster: btn.Request?.cluster || record?.cluster || "default",
    };
    let params = {};
    let schema = btn.schema && JSON.parse(JSON.stringify(btn.schema));
    for (let key in schema) {
      if (Array.isArray(record)) {
        let arr1 = [];
        record.map((obj) => arr1.push(obj[key]));
        params["_" + key] = JSON.stringify(arr1);
      } else {
        params["_" + key] = record[key] ? record[key] : schema[key];
      }
    }
    obj.additional = params;
    console.log(params);
    let arr = [];
    arr.push(obj);
    setSelectQueryModalData(record);
    setQueryModalData(arr);
    setQueryModalVisible(true);
  };
  const exportData = async (btn, rowKeys, selectedRows, record) => {
    console.log(record);
    let params = JSON.parse(JSON.stringify(btn.schema));
    for (let key in params) {
      params[key] = record[key] ? record[key] : params[key];
    }
    let parameters = {};
    for (let pkey in params.parameters) {
      parameters["_" + pkey] = record[pkey];
    }
    params.parameters = parameters;
    console.log(params);
    let res = await commonService.ExportData(params);
    if (res && res.success) {
      notification.success({
        message: "导出提示",
        description: <div dangerouslySetInnerHTML={{ __html: res.msg }}></div>,
        placement: "topRight",
        duration: 0,
      });
    }
  };
  // 点击会议名称回调
  const goDetail = (text, record) => {
    console.log(text, record);
  };
  const dataRadioCallback = (data) => {
    console.log(data);
    setHeaderPortals(data && data?.headerPortals);
    setRadioQueryData(data);
    setModules(data.component);
    data.mongodbSet && setMongodbSet(data.mongodbSet);
  };

  const reloadData = () => {
    const rnd = new Date().getTime();
    setHandleMethodData(rnd);
  };
  const iqueryDetailDrawerCallback = (data) => {

    setIqueryDetailDrawerVisible(false);
    let isUnReload = ['allEventLibrary'].includes(SmartStorage.get("menuParams")?.UrlParams[0]?.pageType)
    console.log("==========111", isUnReload)
    if (!isUnReload) {
      const rnd = new Date().getTime();
      reloadData();
      data && data.loadData && setHandleMethodData(rnd);
    }

  };
  const handleOk = async () => {
    console.log(rowid);
    if (rowid) {
      console.log(monacoEditor.getValue());
      let data = JSON.parse(monacoEditor.getValue());
      let params = {
        ConnectionKey: "tpm",
        DB: mongodbSet.DB,
        Collection: mongodbSet.Collection,
        _id: rowid,
        Set: data,
      };
      console.log(params);
      let res = await commonService.EditSettings(params);
      if (res && res.success) {
        setModalVisible(false);
        monacoEditor.dispose();
        message.success("修改成功");
        reloadData();
      }
    } else {
      setModalVisible(false);
    }
  };
  /**
   *
   * @param {*} btn 按钮key
   * @param {*} item  按钮整体
   * @param {*} items  按钮数组
   */

  // 下载模板
  const downloadTemplateFile = (btn) => {
    let url = btn.val;
    window.open(url);
  };
  const closeModal = () => {
    monacoEditor.dispose();
    setModalVisible(false);
  };
  const batchModifyMethod = () => {
    if (selectedRows.length === 0) {
      message.error("请选择审核数据");
      return;
    }
    setBatchAuditVisible(true);
  };
  const batchAuditCallback = () => {
    setBatchAuditVisible(false);
  };
  const getQueryObject = (res) => {
    setQueryObject(res);
  };
  const getListData = (data) => {
    // console.log("----------", data);
    setListData(data);
  };
  const getDataParame = (data) => {
    setDataParame(data);
  };
  const queryModalVisibleCallback = (flag) => {
    setQueryModalVisible(false);
    flag && reloadData();
  };
  const mopModalVisibleCallback = (flag) => {
    setMopModalVisible(false);
    flag && reloadData();
  };
  // 二维码关闭弹窗
  const qrcodeCancel = (status) => {
    setQrcodeVisable(status);
    queryModalVisibleCallback(true);
  };
  const functionBtnHandleClick = (btn, keys, data) => {
    if (btn.ref) {
      // eslint-disable-next-line no-eval
      eval(btn.ref).current.handle(btn, keys, data);
    }
  };
  useEffect(() => {
    getQueryDatahandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ViewFileList = () => {
    return <div>
      {
        // fileName 
        // url
        queryFileList.map((item, index) => {



          return <div className="fileListItemView" key={index}>

            {
              /(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i.test(item.fileName) && <img src={item.url} />
            }

            {
              !/(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i.test(item.fileName) && <FileTextOutlined className="fileIcon" />
            }

            <a href={item.url} target="_blank" rel="noopener noreferrer">{item.fileName}</a>
          </div>
        })
      }
    </div>
  };


  // 操作按钮回调(详情)

  const rowMenuHandleClick = (e, record, item) => {




    // 存入相关微应用tenantCode 供微应用模块卡控
    if (record.tenantCode) {
      setCookie("tenant", record.tenantCode, { expires: String });
    }
    console.log("====rowBotton操作标识符====", item);


    // 老毕规定的新的操作符类别
    if (item.actionType) {
      switch (item.actionType) {
        case "xform":
          applyXform(item, record);
          break;
        case "yida":
          applyYida(item, record);
          break;
      }
      return
    }
    switch (item.actionCode) {
      case "detail":
        if (item.showWay === "window") {
          SmartStorage.set("detailRow", record);
          SmartStorage.set("radioQueryData", radioQueryData);
          //将routerWay中的占位符用 context 填充
          let context = record
          let routerPath = item.routerWay.replace(/\{\{(.*?)\}\}/g, (match, key) => context[key.trim()]);
          window.open(routerPath);
        } else {
          if (
            ["/final/invoice-task", "/finance/invoice-task"].includes(
              props.location.pathname
            )
          ) {
            // 客户开具发票代办跳转到微应用的财务详情，传递taskId作为参数
            SmartStorage.set("previousPath", props.location.pathname);
            window.open(
              window.location.origin +
              `/micro-apps/Finance-detail?taskId=${record.taskId}&currentRouteReturn`
            );
          } else {
            setDrawerData(record);
            setIqueryDetailDrawerVisible(true);
          }
        }
        break;
      case "edit":
        monacoEditor = null;
        let data = record.$set;
        console.log(data);
        setRowId(record._id);
        setRowDate(data);
        setModalVisible(true);
        setTimeout(() => {
          monacoEditor = monaco.editor["create"](
            document.querySelector("#containerId"),
            {
              value: data,
              language: "json",
              theme: "vs-dark",
            }
          );
          setTimeout(() => {
            monacoEditor.trigger("editor", "editor.action.formatDocument");
          }, 150);
        }, 200);

        break;
      case "genericExport":
        exportData(item, selectedRowKeys, record, record);
        break;
      case "genericQueryModal":
      case "addBind":
      case "boxDetail":
        showQueryModal(item, record);
        break;
      case "removeBind":
      case "delBox":
      case "confirmBox":
      case "genericMethod":
      case "scanHandled":
      case "notHaveScan":
      case "reScan":
        genericMethod(item, selectedRowKeys, record, record);
        break;
      case "operationMethod":
        operationMethod(item, record);
        break;
      case "publicMethod": // 使用公共方法删除
        const params = {
          rowId: record.rowId,
          userId: record.userId,
        };
        item.parameters.body = params;
        confirmMethod(item, record);
        break;
      case "viewInvoice":
        viewInvoice(record);
        break;
      case "btn_Log":
        setMopModalData(record);
        setMopModalVisible(true);
        break;
      case "customerMessage":
        setRowDate(record);
        setMessageModalVisible(true);
        break;
      //查看文件
      case "checkFile":
        checkFile(item, record)
        break;
      case "btn-enable": // 启用
      case 'btn-disable':  // 禁用
      case 'btn-export':  // 导出
        mopExamine(item, record)
        break;
      case "addWaterFile":  // 补水单"addInvoiceFile"
        setRowDate(record);
        setUploaditem(item)
        setUploadTitle('补水单')
        setUploadVisible(true)
        break;
      case "addInvoiceFile":  // 补发票
        setRowDate(record);
        setUploaditem(item)
        setUploadTitle('补发票')
        setUploadVisible(true)
        break;
      case "file-gallery":
        goGallery(item, record);

        break;
      default:
        console.log(item);
        console.log(modules);
        // item.ref = 'HotelAuditEditRef';
        // console.log(eval(item.ref));
        console.log(eval(item.ref).current);
        eval(item.ref).current.handle(item, selectedRowKeys, record, record);
        break;
    }
  };
  const supplementModalCallback = () => {
    setUploadVisible(false)
  };
  const saveInvoice = () => {
    setUploadVisible(false)

  }
  const mopExamine = (btn, record) => {
    let params = {
      customerId: record.customerId
    }
    if (["btn-enable", "btn-disable"].includes(btn.actionCode)) {
      params.Status = ["btn-enable"].includes(btn.actionCode) ? '启用' : '停用'
    }
    commonHandle(params, btn);
  }
  const headerBtnHandleClick = (btn, item, items) => {

    console.log("====HeaderBotton操作标识符====", btn, item, items, selectedRows, selectedRowKeys);
    // eaderBotton操作标识符==== 
    if (btn.actionType) {
      switch (btn.actionType) {
        case "xform":
          if (
            btn.checked &&
            selectedRows.length < 1
          ) {
            message.info("请先勾选一条数据");
            return;
          }
          applyXform(btn, selectedRows);
          break;
        case "yida":
          applyYida(btn, selectedRows);
          break;
        case "subQuery":
          DiffReconciliationRef.current.handle(item ? item : btn, selectedRowKeys, selectedRows);
          break;
      }
      return
    }


    let data = item ? item : btn;
    switch (data.actionCode) {
      case "genericQueryModal":
        showQueryModal(data, selectedRows);
        break;
      case "confirmMethod":
        confirmMethod(data, selectedRowKeys);
        break;
      case "btn_DownloadTemplateFile": // 下载模板的公共方法，传递val（url）作为下载链接，参照酒店资源导入-基本信息-下载模板
        downloadTemplateFile(data);
      default:
        if (data.ref) {
          // eslint-disable-next-line no-eval
          eval(data.ref).current.handle(data, selectedRowKeys, selectedRows);
        }
        break;
    }

    // switch (btn.actionCode||item.val) {
    //     case "batchAudit":
    //         setActionBtn(btn);
    //         break;
    //     default:
    //         break;
    // }

  };
  const goGallery = (item, record) => {
    // let radioQuery = JSON.parse(JSON.stringify(radioQueryData))
    // radioQuery.pageType = 'smartGallery';
    // setRadioQueryData(radioQuery)
    let json = {
      tenantId: record.tenantId,
      num: record.poNum,
      type: "smartGallery"
    }
    setDrawerData(json);
    setIqueryDetailDrawerVisible(true);
    // window.open((btn.actionUrl||'/shared/smart-gallery')+'?tenantId='+row.tenantId+'&num='+row.poNum, '_blank');
  }
  return (
    getQueryData && (
      <>
        <XformContext.Provider value={{ XformData, reloadData, setYidaOpen }}>
          <div
            className={
              urlParamsObjArr && urlParamsObjArr[0]?.queryId
                ? "iqueryDetail"
                : "iqueryBox"
            }
          >
            {modules &&
              modules.length > 0 &&
              modules?.map((x, index) => {
                const TagName = modelComponents[x];
                return (
                  x && (
                    <TagName
                      key={index}
                      // eslint-disable-next-line no-eval
                      ref={eval(x + "Ref")}
                      reloadData={reloadData}
                      queryModalRef={queryModalRef}
                      queryModalVisibleCallback={queryModalVisibleCallback}
                      dataParame={dataParame}
                      queryObject={queryObject}
                      getQueryData={getQueryData}
                    />
                  )
                );
              })}
            <Modal
              title="客服记录"
              destroyOnClose
              visible={messageModalVisible}
              width={800}
              footer={[
                <Button
                  key="back"
                  type="primary"
                  onClick={(e) => {
                    setMessageModalVisible(false);
                  }}
                >
                  关闭
                </Button>,
              ]}
              onCancel={(e) => {
                setMessageModalVisible(false);
              }}
            >
              <CustomerDetail choiseItem={rowDate}></CustomerDetail>
            </Modal>

            <Modal title="查看文件" open={viewFileVisible} onOk={() => {
              setViewFileVisible(false);
            }} onCancel={() => {
              setViewFileVisible(false);
            }}>

              {
                queryFileList.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }

              {
                queryFileList.length > 0 && <ViewFileList />
              }

            </Modal>

            {
              UploadVisible &&
              <SupplementModal
                rowDate={rowDate}
                UploadTitle={UploadTitle}
                UploadItem={UploadItem}
                supplementModalVisible={UploadVisible}
                supplementModalCallback={supplementModalCallback}
                gatewayPath={gatewayPath}
              ></SupplementModal>

            }

            <Modal
              title={rowid ? "编辑" + getQueryData[0]?.queryName : "JSON-VIEWER"}
              width="800px"
              visible={modalVisible}
              onOk={handleOk}
              onCancel={closeModal}
            >
              <div id="containerId" style={{ height: "400px" }}></div>
            </Modal>
            {qrcodeVisable && (
              <QrcodeModal
                qrcodeVisable={qrcodeVisable}
                qrcodeData={qrcodeData}
                qrcodeCancel={qrcodeCancel}
              ></QrcodeModal>
            )}
            <BatchAudit
              actionBtn={actionBtn}
              batchAuditVisible={batchAuditVisible}
              batchAuditCallback={batchAuditCallback}
              reloadData={reloadData}
              selectedRows={selectedRows}
            ></BatchAudit>
            {queryModalVisible && (
              <QueryModal
                ref={queryModalRef}
                rowMenuHandleClick={rowMenuHandleClick}
                selectQueryModalData={selectQueryModalData}
                queryModalBtnData={queryModalBtnData}
                queryModalData={queryModalData}
                headerBtnHandleClick={headerBtnHandleClick}
                queryModalVisibleCallback={queryModalVisibleCallback}
                queryModalVisible={queryModalVisible}
              ></QueryModal>
            )}
            {mopModalVisible && (
              <MopLog
                mopModalData={mopModalData}
                mopModalVisibleCallback={mopModalVisibleCallback}
                mopModalVisible={mopModalVisible}
              ></MopLog>
            )}
            {iqueryDetailDrawerVisible && (
              <IqueryDetailDrawer
                radioQueryData={radioQueryData}
                drawerData={drawerData}
                queryObject={queryObject}
                iqueryDetailDrawerVisible={iqueryDetailDrawerVisible}
                iqueryDetailDrawerCallback={iqueryDetailDrawerCallback}
              />
            )}
            {/*-- 全品类预算看板 - 统计数据 --*/}
            {subassemblyList.map((item, index) =>
              React.createElement(components[item.name], {
                key: index,
                filterData: dataParame,
                defaultParams: item.params || {},
              })
            )}
            {gatewayPath && (
              <SmartQueryReact
                gatewayPath={process.env.REACT_APP_GATEWAY}
                iSignalr={iSignalr}
                goDetail={goDetail}
                getQueryObject={getQueryObject}
                handleMethod={handleMethodData}
                dataRadioCallback={dataRadioCallback}
                headerBtnHandleClick={headerBtnHandleClick}
                functionBtnHandleClick={functionBtnHandleClick}
                batchModifyMethod={batchModifyMethod}
                headerPortals={
                  headerPortals ? (
                    <Action
                      reloadData={reloadData}
                      headerPortals={headerPortals}
                    />
                  ) : (
                    ""
                  )
                }
                rowMenuHandleClick={rowMenuHandleClick}
                returnSelectedRows={returnSelectedRows}
                getQueryData={getQueryData}
                getListData={getListData}
                showJsonViewer={showJsonViewer}
                getDataParame={getDataParame}
              ></SmartQueryReact>
            )}
            {urlParamsObjArr[0]?.queryId && listData && listData.length < 1 && (
              <div className="detailTips">
                <p>未查询到任何信息</p>
              </div>
            )}


            <Xform
              XformData={XformData}
              reloadData={reloadData}
              ref={xFormModalRef}
            ></Xform>
            <Modal
              title={yidaModeltitle}
              open={yidaOpen}
              onCancel={() => {
                setYidaOpen(false);
              }}
              onOk={() => {
                setYidaOpen(false);
              }}
              destroyOnClose={true}
              footer={false}
              width={Number(yidaModel?.modalWidth) || 1200}
            >
              <Yida model={yidaModel}></Yida>
            </Modal>
          </div>
        </XformContext.Provider>
      </>
    )
  );
};
export default IqueryPageList;
