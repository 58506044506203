import { Button, Modal, message } from "antd";
import SmartQueryReact from "smart-iquery-react";
import commonService from "@/service/common";
import { useHistory } from "react-router-dom";
import React, {
  memo,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from "react";
import { createForm } from "@formily/core";
import { createSchemaField } from "@formily/react";
import { getCookie, setCookie } from "tiny-cookie";
import { useGeneric } from "@/controllers/useGeneric";
import moment from "moment";

// 引入自定义组件
// import Switchcopy from "./Switchcopy";
// 引入自定义组件end
import {
  Form,
  FormItem,
  FormLayout,
  Input,
  Select,
  Password,
  Cascader,
  DatePicker,
  Submit,
  Radio,
  Checkbox,
  Space,
  FormGrid,
  Upload,
  ArrayItems,
  NumberPicker,
  TreeSelect,
  Editable,
  TimePicker,
  FormButtonGroup,
  Switch,
  PreviewText,
  Reset,
  Transfer,
  FormTab,
  FormCollapse,
  ArrayTable,
  ArrayCards,
} from "@formily/antd";

let IqueryModal = forwardRef((props, ref) => {


  const [iqueryModal, setIqueryModal] = useState({});
  // 暴露方法给父级应用
  useImperativeHandle(ref, () => ({
    setIqueryModal,
  }));
  let { handelFormAction } = props;
  return (
    <Modal
      onOk={() => {
        if (eval(iqueryModal.beforeOk)) {
          setIqueryModal({ ...iqueryModal, open: false });
        }
      }}
      onCancel={() => {
        setIqueryModal({ ...iqueryModal, open: false });
      }}
      title={iqueryModal.title}
      open={iqueryModal.open}
      destroyOnClose={true}
      width={iqueryModal.width || 1000}
    >
      <SmartQueryReact
        gatewayPath={process.env.REACT_APP_GATEWAY}
        getQueryData={iqueryModal.queryData}
        addClass="tableStyle"
        returnSelectedRows={(rowsId, rows) => {
          console.log("====选中项====", rowsId, rows);
          iqueryModal.selectedRows = rows;
        }}
        rowMenuHandleClick={() => { }}
        handleMethod={() => { }}
        headerBtnHandleClick={() => { }}
      ></SmartQueryReact>
    </Modal>
  );
});

const App = forwardRef((props, ref) => {
  
  const { replaceEvalObject } = useGeneric();
  const [open, setOpen] = useState(false);
  const { XformData, reloadData, info, isPage,relevanceData } = props;
  const history = useHistory();
  const iqueryModalRef = useRef();
  const [formSchema, setFormSchema] = useState({
    form: {},
    schema: {},
  });

  const showModal = async () => {
    // 拉取formSchema
    console.log("====XformData====", XformData);
    getFromSchema()
      .then((formSchema) => {
        console.log("====加载formSchema成功====", formSchema);
        setFormSchema(formSchema);
      })
      .catch((err) => {
        console.log("====加载formSchema失败====", err);
      });
  };

  const getFromSchema = async () => {
    let [belongApp, formName] = XformData.formRef.split(".");
    let res = await commonService.common(
      "post",
      "/ndata/api/xquery/getQueryData",
      {
        QueryId: "5005449b-29a0-4021-b7de-f6d94cb99cc0",
        cluster: "default",
        parameters: {
          deconstruct: false,
          _query: {
            "form.belongApp": belongApp,
            "form.formName": formName,
          },
        },
      }
    );
    if (res && res.success && res.content) {
      if (res.content.rows[0]) {
        return res.content.rows[0];
      } else {
        throw new Error("没有找到schema");
      }
    } else {
      throw new Error("查询条件失败");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  //渲染表单
  let form = createForm({
    validateFirst: true,
  });

  // console.log("====xform实例====", form, XformData?.formRef);

  // 其他位置处理form表单数据
  let handelFormAction = (actionData) => {
    try {
      console.log("=======xformMessageEvent=======", actionData, form.id);
      switch (actionData.actionType) {
        case "openModal":
          showModal();
          break;
        case "submit":
          handleOk(false, actionData.validate);
          break;
        case "selectEvent":
          form.selectedRows = actionData.rows;
          break;
        case "setValue":
          form.fields[actionData.field].setValue(actionData.value);
          break;
      }
    } catch (error) {
      console.log("=======xformMessageEvent error=======", error);
    }
  };

  // 暴露方法给父级应用
  useImperativeHandle(ref, () => ({
    handelFormAction,
    handleFlow: (btn = {}) => {
      xformValidateFields(btn);
    },
  }));

  /**
  * 提交
  * @param {*} btn 按钮信息
  */
  const xformValidateFields = (btn) => {
    form
      .validate()
      .then(() => {
        props.onSumbit(form.getState().values, btn);
      })
      .catch((err) => {
        console.log(err, "errorInfo");
      });
  };

  // 尝试使用formid创建信道
  if (XformData) {
    let [belongApp, formName] = XformData?.formRef?.split(".");
    window[formName + "Channel"] = new MessageChannel();
    window[formName + "Channel"].port1.onmessage = (event) => {
      handelFormAction(event.data);
    };
  }

  /**
   * 
   * @param {Boolean} isNoModal 直接提交，需要弹出框
   * @param {Boolean} onlyValidate 不提交，只执行validator，然后返回数据
   */
  const handleOk = (isNoModal, onlyValidate) => {
    // 验证表单成功后回调方法
    let afterValuesValid = async () => {
      let formDataProto = isNoModal === true ? {} : form.getState().values;
      console.log("====表单原始数据====", formDataProto);
      let originFormData = Object.keys(formDataProto).reduce((acc, key) => {
        if (!/^_.*_$/.test(key)) {
          acc[key] = formDataProto[key];
        }
        return acc;
      }, {});
      console.log("originFormData:", originFormData);

      // 如果配置了XformData?.btnData?.replaceEvalPath，则需要解析数据并做对应处理，如表单初始化赋值等操作
      // 需要考虑传入数据XformData.record类型，object、array、string、number等 
      if (XformData?.btnData?.replaceEvalPath && XformData?.btnData?.interfaceParam) {
        let item = replaceEvalObject(XformData?.btnData, { ...{ record: XformData?.record }, ...originFormData })
        originFormData = item.interfaceParam
      }
      console.log("====排除仅显示字段后数据====", originFormData);

      // 开始最后提交前的数据处理
      console.log("====开始执行提交前函数====", formSchema.form.onFormOk);
      let $scope = {
        $formData: structuredClone(originFormData),
        $record: structuredClone(XformData.record),
        $btnInfo: structuredClone(XformData.btnInfo),
        $gateway: process.env.REACT_APP_GATEWAY,
        $token: getCookie("x_token") || getCookie("token"),
        $http: commonService,
        $cookie: { getCookie, setCookie },
        $form: form,
        $history: history,
        $message: message,
        $info: info,
        $moment: moment,
        $relevanceData:relevanceData
      };
      //执行函数
      try {
        if (formSchema.form.onFormOk) {
          new Function(
            ...Object.keys($scope),
            "$scope",
            formSchema.form.onFormOk
          )(...Object.values($scope), $scope);
        }

        // 提交表单数据
        console.log(
          "====开始提交表单数据==== api:",
          formSchema.form.actionURL,
          $scope
        );
        let res = await commonService.common(
          "post",
          formSchema.form.actionURL,
          $scope.$formData
        );
        if (res && res.success) {
          setOpen(false);
          XformData?.refresh && props.iqueryDetailDrawerCallback()
          reloadData && reloadData();
          message.success(res.msg || "操作成功");
        }
      } catch (error) {
        console.log("=======执行提交前函数error=======", error);
      }
    };

    // 表单验证然后通知flowAction
    if (onlyValidate) {
      console.log("====form.getState()====", form.getState());
      form.validate().then(() => {
        props.onSumbit(form.getState().values);
      });
    } else {
      // 检查表单验证是否通过, 验证通过后xform 直接走提交流程
      form
        .validate()
        .then(afterValuesValid)
        .catch((error) => {
          console.log("=======error=======", error);
        });
    }
  };

  let $projectId = "";
  try {
    $projectId = window.location.search
      .split("&")
      .map((x) => x.split("=")[1])[0];
  } catch (error) { }

  let scope = {
    $token: getCookie("x_token") || getCookie("token"),
    $gateway: process.env.REACT_APP_GATEWAY,
    $http: commonService,
    $message: message,
    $history: history,
    $cookie: { getCookie, setCookie },
    $setIqueryModal: (...args) => {
      iqueryModalRef.current.setIqueryModal(...args);
    },
    $info: info,
    $projectId,
    $moment: moment,
    $relevanceData:relevanceData
  };

  Object.defineProperty(scope, "$record", {
    get() {
      return XformData.record;
    },
    enumerable: true,
    configurable: true,
  });

  const SchemaField = createSchemaField({
    components: {
      Form,
      FormItem,
      FormLayout,
      Input,
      Select,
      Password,
      Cascader,
      DatePicker,
      Submit,
      Radio,
      Checkbox,
      Space,
      FormGrid,
      Upload,
      ArrayItems,
      NumberPicker,
      TreeSelect,
      Editable,
      TimePicker,
      FormButtonGroup,
      Switch,
      PreviewText,
      Reset,
      Transfer,
      FormTab,
      FormCollapse,
      ArrayTable,
      ArrayCards,
    },
    scope,
  });

  useEffect(() => {
    if (formSchema?.form?.belongApp) {
      if (formSchema.form && formSchema.form.noModal) {
        console.log("====直接执行onFormOk====", formSchema.form.onFormOk);
        handleOk(true);
      } else {
        setOpen(true);
      }
    }
  }, [formSchema]);

  useEffect(() => {
    if (isPage) {
      showModal();
    }
  }, []);

  return (
    <div>
      {isPage && (
        <div>
          <Form
            form={form}
            labelCol={formSchema.form.labelCol}
            wrapperCol={formSchema.form.wrapperCol}
          >
            <SchemaField schema={formSchema.schema} />
          </Form>

          <Button type="primary" onClick={handleOk}></Button>
        </div>
      )}

      {!isPage && (
        <Modal
          title={formSchema.form.formTitle}
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          width={Number(XformData?.modalWidth) || 500}
        >
          <Form
            form={form}
            labelCol={formSchema.form.labelCol}
            wrapperCol={formSchema.form.wrapperCol}
          >
            <SchemaField schema={formSchema.schema} />
          </Form>
        </Modal>
      )}

      <IqueryModal
        handelFormAction={handelFormAction}
        ref={iqueryModalRef}
      ></IqueryModal>
    </div>
  );
});

export default App;
