import React, { useState, useEffect } from "react";

import XInput from "./xInput";
import XNumberInput from "./xNumberInput";
import XRadio from "./xRadio";
import XSelect from "./xSelect";
import XSubTitle from "./xSubTitle";
import XTextArea from "./xTextArea";
import XCheckBox from "./xCheckBox";
import XCascader from "./xCascader";
import XCountryCascader from "./xCountryCascader";
import XRangePicker from "./xRangePicker";
import XDatePicker from "./xDatePicker";
import XCascaderSelect from "./xCascaderSelect";
import XChainDrugSelect from "./xChainDrugSelect";
import XProductSkuSelect from "./xProductSkuSelect";
import XCascaderCity from "./xCascaderCity";
import XCustomFileView from "./xCustomFileView";
import XTimePicker from "./xTimePicker";
import xTimeRangePicker from "./xTimeRangePicker";
// iquery 版本的selsect
import xSelectQ from "./xSelectQ";

const FormItem = (props) => {
  console.log(props);
  const [itemList, setItemList] = useState(props.itemList);

  const components = {
    input: XInput,
    numberInput: XNumberInput,
    radio: XRadio,
    select: XSelect,
    subTitle: XSubTitle,
    textArea: XTextArea,
    checkBox: XCheckBox,
    cascader: XCascader,
    countryCascader: XCountryCascader,
    timePicker: XTimePicker,
    rangePicker: XRangePicker,
    datePicker: XDatePicker,
    cascaderSelect: XCascaderSelect,
    chainDrugSelect: XChainDrugSelect,
    productSkuSelect: XProductSkuSelect,
    cascaderCity: XCascaderCity,
    customFileView: XCustomFileView, // 自定义文件预览
    timeRangePicker: xTimeRangePicker,
    selectQ:xSelectQ
  };

  const customerCallBack = (e, item, sData) => {
    props.callBack(e, item, sData);
  };

  useEffect(() => {
    setItemList(props.itemList);
  }, [props.itemList]);

  return (
    <>
      {itemList.length > 0 &&
        itemList.map((item, index) =>
          React.createElement(components[item.type], {
            key: index,
            item: item,
            isRest: props.isRest,
            callback: customerCallBack,
            onFocus: ({ item }) => {
              props.onFocus && props.onFocus({ item: item });
            },
            formInfo: props.formInfo,
          })
        )}
    </>
  );
};

export default FormItem;
