import React, { useEffect, forwardRef, useState } from "react";
import { Button, Badge, Popover, message } from "antd";
// eslint-disable-next-line no-unused-vars
import { getCookie } from "tiny-cookie";
import { LinkOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
import commonService from "@/service/common";
const SmartPayButton = forwardRef((props, ref) => {
  const [visitorsList, setVisitorsList] = useState([]);
  useEffect(() => {
    queryPayVisitors();
  }, []);
  const queryPayVisitors = () => {
    let params = {
      "status": 1 
    }
    commonService.QueryPayVisitors(params).then((res) => {
      if (res && res.success) {
        setVisitorsList(res.content)
      }
    })
  }
  const handleBtn = (data) => {
    let params = {
      id: data.id, 
      expenseId: data.expenseId
    }
    commonService.CancelPayVisitor(params).then((res) => {
      if (res && res.success) {
        queryPayVisitors();
        message.success("作废成功")
      }
    })
  }
  const content = (
    <div className="payDetail">
      {visitorsList.length > 0 && visitorsList.map(ele => {
        return (<div>
          <Button className="payBtn" type="primary" onClick={(e) => handleBtn(ele)}>
            作废
          </Button>
          <p>取号时间:{ele.dtRequest}</p>
          <p>下单人: {ele.createdBy}</p>
          <p>供应商： {ele.supplierName}</p>
          <p>会议编号： {ele.poNum}</p>
          <p></p>
        </div>)
      })}
    </div>
  );
  return (
    <div>
      <Popover
        placement="left"
        title="排队情况"
        content={content}
        trigger="click"
      >
        <Badge count={visitorsList.length}>
          <Button type="link">
            排队详情
            <LinkOutlined />
          </Button>
        </Badge>
      </Popover>
    </div>
  );
});
export default SmartPayButton;
