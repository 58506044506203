import React, { useEffect, useState, useRef } from "react";
import {
    Button, Form,
    Upload,
    Input,
    Tooltip
} from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import commonService from "@/service/common";

const RemakeRecognition = (props) => {
    const {
        selectData,
        modeType
    } = props;
    const [form] = Form.useForm();

    const [modalLoding, setModalLoding] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [recognitionResault, setRecognitionResault] = useState(null);
    useEffect(() => {
        console.log(selectData, modeType, "----selectData")
        selectData && form.setFieldValue('url', selectData.url)
        setRecognitionResault('')

    }, [selectData]);
    const fileChange = (e) => {
        console.log(e.target.files[0])//文件对象到手
        form.setFieldValue('file', e.target.files[0])
    }
    const remakeSubmit = () => {
        form.validateFields().then((data) => {
            const formData = new FormData();
            formData.append('url', data.url)
            formData.append('file', data.file)
            formData.append('mode', modeType)

            commonService.ReMakeSubmit(formData).then(res => {
                console.log(res)
                setRecognitionResault(res.msg)
            })
        });

    }
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    return (<>
        <div className="remakeRecognitionMain">
            <div style={{ width: collapsed ? '70%' : '92%' }} className="remakeRecognitionRightBox">
                <Form
                    form={form}
                    name="advanced_search"
                    className="gallery-search-form"
                >
                    <Form.Item label="图片URL" name="url">
                        <Input />
                    </Form.Item>
                    <Form.Item label="本地上传" name="file">
                        {/* <input id="fileInput" style={{ display: 'none' }} type="file" onChange={(e) => { fileChange(e) }} /> */}
                        {/* <div onClick={() => {
                            console.log('click')
                            document.getElementById('fileInput').click();
                        }}> */}
                        <Upload
                            maxCount={1}
                            onChange={(file) => {
                                console.log(file, 'change')
                                form.setFieldValue('file', file.file)
                                return Upload.LIST_IGNORE;
                            }}
                            // openFileDialogOnClick={false}
                            listType="picture-card"
                        >
                            上传文件
                        </Upload>
                        {/* </div> */}

                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        wrapperCol={{
                            offset: 20
                        }}
                    >
                        <Button loading={modalLoding} type="primary" htmlType="submit" onClick={() => { remakeSubmit() }}>
                            识别
                        </Button>
                    </Form.Item>
                </Form>
                <div className="remakeRecognitionResault">
                    <div className="remakeRecognitionResultTitle">识别结果</div>
                    <div>
                        {recognitionResault}
                    </div>
                </div>
            </div>
            <div className={collapsed ? "remakeRecognitionLeftBox" : "remakeRecognitionLeftBoxCollapsed"}>
                {/* <Tooltip placement="rightTop" title='查看体验历史' color="black">  */}
                <Button
                    type="primary"
                    onClick={toggleCollapsed}
                    style={{
                        marginBottom: 16,
                    }}
                >
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
                {/* </Tooltip> */}
                {
                    // collapsed && 
                    <div className="remakeRecognitionLeftBoxTitle">体验历史</div>
                }

            </div>
        </div>
    </>
    )
}
export default RemakeRecognition;