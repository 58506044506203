import React, { useState } from "react";
import { Switch, /* Redirect, */ Route } from "react-router-dom";

// import Workbench from "../pages/Workbench";

// import LectureManageList from "@/pages/LectureManage/List";
// 支付宝订单
// import JGridReport from "@/pages/JGridReport";
// 紧急支付
// import emergencyPayment from "@/pages/Finance/EmergencyPayment"

// import reportManage from "../pages/ReportManage";

import NoMatch from "../pages/NoMatch";

// 测试乾坤菜单
// import FinancePlatform from "@/pages/Finance/FinancePlatform/List";
// 前端站点
// import XFrontend from "@/pages/XFrontend/List";
//iquery
import HomePage from "../pages/HomePage";
// import menus from "@/assets/mock/menus";
// 微应用页面-餐厅库
// import ShopLibrary from "@/pages/HomePage/ShopLibrary/ShopLibrary";
// 微应用页面-医院库
// import HospitalLibrary from "@/pages/HomePage/HospitalLibrary/HospitalLibrary";
//个人设置
import AccountSettings from "@/pages/AccountSettings";
// 场地库
// import PlaceLibrary from "@/pages/HomePage/PlaceLibrary/PlaceLibrary";
// 会议操作中心
import MeetingOperation from "@/pages/HomePage/MeetingOpsCenter/MeetingOpsCenter";
// 操作中心详情
import MopMeetingDetail from "@/pages/HomePage/MeetingOpsCenter/components/mopMeetingDetail/MopMeetingDetail";
// 场地库详情
// import VenuePlaceDetail from "@/pages/HomePage/PlaceLibrary/VenuePlaceDetail";
// 财务中心-财务工作台
import FinanceWorkPanel from "@/pages/HomePage/Finance/FinanceWorkPanel";
// 财务中心-收款管理
import ReceivablesManage from "@/pages/HomePage/Finance/ReceivablesManage";
// 财务中心-交易查询
import TradeManage from "@/pages/HomePage/Finance/TradeManage";
// 财务中心-第三方对账
import ThirdReconciliation from "@/pages/HomePage/Finance/ThirdReconciliation";
// 财务中心-财务工作台-详情
import FinanceWorkPanelDetails from "@/pages/HomePage/Finance/FinanceWorkPanel/FinanceWorkPanelDetails";
//资金管理-资金日记明细
import FundLogDetails from "@/pages/HomePage/FundManage/FundLogDetails";

// 协办会-工作台
// import WorkPlace from "@/pages/CrossEvent/WorkPlace";
import MicroApp from "@/pages/WithOutMicroPage/MicroApp";
// 协办会-详情页
// import TaskDetail from "@/pages/CrossEvent/TaskDetail";

import IqueryPageList from "@/pages/HomePage";
//资金池看板
import FundingSummary from "@/pages/FundingSummary";
//G Car订单统计
import GCarSummary from "@/pages/GCarSummary";
//用户模拟
import AppSimulator from "@/pages/AppSimulator";
// 发票-批量扫描
import InvoiceBatchScan from "@/pages/HomePage/Invoices/InvoiceBathScan/InvoiceBatchScan";
// 详情新窗口
import DetailPage from "@/components/DetailDrawer/DetailPage";
import CarDetailPage from "@/components/DetailDrawer/CarDetailPage";
import SmartGallery from "@/pages/SmartGallery";
const components = {
  // Workbench: Workbench,
  // LectureManageList: LectureManageList,
  // XFrontend: XFrontend,
  // FinancePlatform: FinancePlatform,
  // reportManage: reportManage,
  NoMatch: NoMatch,
  // alipayOrderList: JGridReport,
  IqueryPage: HomePage,
  // HospitalLibrary: HospitalLibrary,
  AccountSettings: AccountSettings,
  // PlaceLibrary: PlaceLibrary, // 场地库
  MeetingOperation: MeetingOperation,
  // VenuePlaceDetail: VenuePlaceDetail, // 场地库详情
  MopMeetingDetail: MopMeetingDetail,
  FinanceWorkPanel: FinanceWorkPanel,
  FundingSummary: FundingSummary,
  GCarSummary: GCarSummary,
  ReceivablesManage,
  FundLogDetails,
  TradeManage,
  AppSimulator,
  ThirdReconciliation,
  FinanceWorkPanelDetails,
  IqueryPageList,
  MicroApp,
  // TaskDetail,
  InvoiceBatchScan, //发票批量扫描
  DetailPage,
  CarDetailPage,
  SmartGallery
};

function generateRoutes(data, result = []) {
  data.forEach((item) => {
    if (item.Component) {
      result.push(
        <Route
          key={item.Path}
          exact
          path={item.Path}
          component={components[item.Component]}
        />
      );
    }
    if (item.subs) {
      generateRoutes(item.subs, result);
    }
  });
  // console.log(result, "1111");
  return result;
}

const MenuRoutes = (props) => {
  const [menuList] = useState([
    ...props.menus,
    ...[
      {
        BreadCrumbLevel: 1,
        Application: "hyq",
        Txt: "accountSettings",
        Value: "accountSettings",
        Parent: "Event",
        Settings: {},
        Path: "/accountSettings",
        Component: "AccountSettings",
        Params: {},
        Tenant: "viatris",
        CreatedOn: "2021-09-27 11:35:06",
      },
      {
        BreadCrumbLevel: 1,
        Application: "hyq",
        Txt: "404",
        Value: "QueryEvent",
        Parent: "Event",
        Settings: {},
        Path: "*",
        Component: "NoMatch",
        Params: {},
        Tenant: "viatris",
        CreatedOn: "2021-09-27 11:35:06",
      },
    ],
  ]);

  return <Switch>{generateRoutes(menuList)}</Switch>;
};

export { MenuRoutes };
